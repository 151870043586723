import * as React from "react";

// markup
const IndexPage = () => {
  const removeAfterDelay = (id, time) => {
    setTimeout(() => {
      document.getElementById(id).remove();
      window.close();
    }, time);
  };

  React.useEffect(() => {
    if (!window.location.href.includes("firstload")) {
      //window.open("http://localhost:8000/caroline?firstload=true", "_self", "");
      var windowFeatures =
        "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
      window.open(
        "https://www.youtube.com/watch?v=b8qBUza1pO0&autoplay=1",
        "_blank",
        windowFeatures
      );
    }
    console.log("open window");
  }, []);
  return (
    <div
      style={{
        height: "98vh",
        width: "100%",
        background: "#",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1>PUSHED TO THE LIMIT</h1>
      </div>
      <div id="123">
        <iframe
          allow="autoplay"
          onLoad={() => {
            removeAfterDelay("123", 12000);
          }}
          width="420"
          height="345"
          src="https://www.youtube.com/watch?v=b8qBUza1pO0&autoplay=1"
        ></iframe>
      </div>
    </div>
  );
};

export default IndexPage;
